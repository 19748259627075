/**
 * Function to return the talk image src for the grid.
 * @returns Image source
 */
const getTalkImageSrc = (links) => {
  let cardImageHref = '/portal/placeholders/diamond-cube.svg';
  links &&
    links.map(({ href, title }) => {
      if (title === 'preview') {
        cardImageHref = href;
      }
    });
  return cardImageHref;
};

export default getTalkImageSrc;
